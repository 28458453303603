/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Seo from '../../seo/inventory';
import Support from 'views/inventory/Support';
import Main from 'layouts/Inventory';
import WithLayout from 'WithLayout';

const ContactViewPage = () => {
  return (
    <WithLayout
      seo={Seo}
      component={Support}
      layout={Main}
    />
  );
};

export default ContactViewPage;
